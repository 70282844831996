<template>
  <div id="main-wrapper">
    <div class="row">
      <div class="col-md-12">
        <div class="panel panel-white">
          <div class="panel-body">
            <div class="panel-heading form">
              Approval In Principle Form
            </div>
            <div class="tab-content">
              <div
                id="tab1"
                class="tab-pane active fade in"
              >
                <div class="row">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="non-float-input">
                          <h3>Product Type</h3>
                          <div class="radio-input-group">
                            <div class="radio-inline">
                              <label>
                                <input
                                  v-model="formDetails.offerType"
                                  v-validate="'required'"
                                  :class="errors.has('formDetails.offerType') ? 'error' : ''"
                                  class="form-control"
                                  data-vv-as="offer type"
                                  name="formDetails.offerType"
                                  type="radio"
                                  value="CARD TO CARD"
                                >Card To Card
                              </label>
                            </div>
                            <div class="radio-inline">
                              <label>
                                <input
                                  v-model="formDetails.offerType"
                                  class="form-control"
                                  name="formDetails.offerType"
                                  type="radio"
                                  value="SALARIED"
                                >Salaried
                              </label>
                            </div>
                          </div>
                          <span
                            v-show="errors.has('formDetails.offerType')"
                            class="help text-danger"
                          >{{ errors.first('formDetails.offerType') }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row m-b-lg">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-sm-12">
                        <h3>Personal Info</h3>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="non-float-input">
                          <label>Title: <i
                            aria-hidden="true"
                            class="fas fa-asterisk text-danger"
                            style="font-size: 7px; vertical-align: text-top;"
                          /></label>
                          <div class="radio-input-group">
                            <div class="radio-inline">
                              <label>
                                <input
                                  v-model="formDetails.title"
                                  v-validate="'required'"
                                  :class="errors.has('formDetails.title') ? 'error' : ''"
                                  class="form-control"
                                  data-vv-as="title"
                                  name="formDetails.title"
                                  type="radio"
                                  value="MR."
                                >Mr.
                              </label>
                            </div>
                            <div class="radio-inline">
                              <label>
                                <input
                                  v-model="formDetails.title"
                                  class="form-control"
                                  name="formDetails.title"
                                  type="radio"
                                  value="MRS."
                                >Mrs.
                              </label>
                            </div>
                            <div class="radio-inline">
                              <label>
                                <input
                                  v-model="formDetails.title"
                                  class="form-control"
                                  name="formDetails.title"
                                  type="radio"
                                  value="MS."
                                >Ms.
                              </label>
                            </div>
                            <div class="radio-inline">
                              <label>
                                <input
                                  v-model="formDetails.title"
                                  class="form-control"
                                  name="formDetails.title"
                                  type="radio"
                                  value="DR."
                                >Dr.
                              </label>
                            </div>
                          </div>
                        </div>
                        <span
                          v-show="errors.has('formDetails.title')"
                          class="help text-danger"
                        >{{ errors.first('formDetails.title') }}</span>
                      </div>
                      <div class="col-lg-3 col-md-6">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.firstName"
                            v-validate="'required'"
                            :class="errors.has('formDetails.firstName') ? 'error' : ''"
                            data-vv-as="first name"
                            name="formDetails.firstName"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">First Name <i
                            aria-hidden="true"
                            class="fas fa-asterisk text-danger"
                            style="font-size: 7px; vertical-align: text-top;"
                          /></span>
                          <span
                            v-show="errors.has('formDetails.firstName')"
                            class="help text-danger"
                          >{{ errors.first('formDetails.firstName') }}</span>
                        </label>
                      </div>
                      <div class="col-lg-3 col-md-6">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.fatherName"
                            v-validate="'required'"
                            :class="errors.has('formDetails.fatherName') ? 'error' : ''"
                            data-vv-as="father name"
                            name="formDetails.fatherName"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">Father Name <i
                            aria-hidden="true"
                            class="fas fa-asterisk text-danger"
                            style="font-size: 7px; vertical-align: text-top;"
                          /></span>
                          <span
                            v-show="errors.has('formDetails.fatherName')"
                            class="help text-danger"
                          >{{ errors.first('formDetails.fatherName') }}</span>
                        </label>
                      </div>
                      <div class="col-lg-3 col-md-6">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.lastName"
                            v-validate="'required'"
                            :class="errors.has('formDetails.lastName') ? 'error' : ''"
                            data-vv-as="last name"
                            name="formDetails.lastName"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">Last Name <i
                            aria-hidden="true"
                            class="fas fa-asterisk text-danger"
                            style="font-size: 7px; vertical-align: text-top;"
                          /></span>
                          <span
                            v-show="errors.has('formDetails.lastName')"
                            class="help text-danger"
                          >{{ errors.first('formDetails.lastName') }}</span>
                        </label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 col-md-6">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.pancard"
                            v-validate="{ required: true, regex: /^[aA-zZ]{5}[\d]{4}[aA-zZ]$/ }"
                            :class="errors.has('formDetails.pancard') ? 'error' : ''"
                            data-vv-as="pancard"
                            name="formDetails.pancard"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">Pancard <i
                            aria-hidden="true"
                            class="fas fa-asterisk text-danger"
                            style="font-size: 7px; vertical-align: text-top;"
                          /></span>
                          <span
                            v-show="errors.has('formDetails.pancard')"
                            class="help text-danger"
                          >{{ errors.first('formDetails.pancard') }}</span>
                        </label>
                      </div>
                      <div class="col-lg-2 col-md-3">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.dateOfBirth"
                            v-validate="'required|date_format:dd-MM-yyyy|age-check'"
                            :class="errors.has('formDetails.dateOfBirth') ? 'error' : ''"
                            class="date-picker"
                            data-vv-as="DOB"
                            name="formDetails.dateOfBirth"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">Date Of Birth <i
                            aria-hidden="true"
                            class="fas fa-asterisk text-danger"
                            style="font-size: 7px; vertical-align: text-top;"
                          /></span>
                          <span
                            v-show="errors.has('formDetails.dateOfBirth')"
                            class="help text-danger"
                          >{{ errors.first('formDetails.dateOfBirth') }}</span>
                        </label>
                      </div>
                      <div class="col-lg-1 col-md-3">
                        <div
                          class="non-float-static"
                          style="padding-top: 0 !important;"
                        >
                          <label>Age:</label>
                          <p class="form-control-static">
                            {{ calcAge }}
                          </p>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-6">
                        <div class="non-float-input">
                          <label>Gender: <i
                            aria-hidden="true"
                            class="fas fa-asterisk text-danger"
                            style="font-size: 7px; vertical-align: text-top;"
                          /></label>
                          <div class="radio-input-group">
                            <div class="radio-inline">
                              <label>
                                <input
                                  v-model="formDetails.gender"
                                  v-validate="'required'"
                                  :class="errors.has('formDetails.gender') ? 'error' : ''"
                                  class="form-control"
                                  data-vv-as="gender"
                                  name="formDetails.gender"
                                  type="radio"
                                  value="MALE"
                                >Male
                              </label>
                            </div>
                            <div class="radio-inline">
                              <label>
                                <input
                                  v-model="formDetails.gender"
                                  class="form-control"
                                  name="formDetails.gender"
                                  type="radio"
                                  value="FEMALE"
                                >Female
                              </label>
                            </div>
                          </div>
                        </div>
                        <span
                          v-show="errors.has('formDetails.gender')"
                          class="help text-danger"
                        >{{ errors.first('formDetails.gender') }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.email"
                            v-validate="'required|email'"
                            :class="errors.has('formDetails.email') ? 'error' : ''"
                            data-vv-as="email"
                            name="formDetails.email"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">Personal Email ID <i
                            aria-hidden="true"
                            class="fas fa-asterisk text-danger"
                            style="font-size: 7px; vertical-align: text-top;"
                          /></span>
                          <span
                            v-show="errors.has('formDetails.email')"
                            class="help text-danger"
                          >{{ errors.first('formDetails.email') }}</span>
                        </label>
                      </div>
                      <div class="col-md-3">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.mobileNumber"
                            v-validate="'required|digits:10'"
                            :class="errors.has('formDetails.mobileNumber') ? 'error' : ''"
                            data-vv-as="mobile number"
                            name="formDetails.mobileNumber"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">Mobile Number (For AIP) <i
                            aria-hidden="true"
                            class="fas fa-asterisk text-danger"
                            style="font-size: 7px; vertical-align: text-top;"
                          /></span>
                          <span
                            v-show="errors.has('formDetails.mobileNumber')"
                            class="help text-danger"
                          >{{ errors.first('formDetails.mobileNumber') }}</span>
                        </label>
                      </div>
                      <div class="col-md-3">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.mobileNumberAlternate"
                            v-validate="'digits:10'"
                            :class="errors.has('formDetails.mobileNumberAlternate') ? 'error' : ''"
                            data-vv-as="alternate number"
                            name="formDetails.mobileNumberAlternate"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">Alternate Number</span>
                          <span
                            v-show="errors.has('formDetails.mobileNumberAlternate')"
                            class="help text-danger"
                          >{{ errors.first('formDetails.mobileNumberAlternate') }}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row m-b-lg">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-sm-12">
                        <h3>Resident Address Details (As Per Document Only)</h3>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.addressLine1"
                            v-validate="{ required: true, min: 3, max: 39, regex: /^[\w\d\s]+$/ }"
                            :class="errors.has('formDetails.addressLine1') ? 'error' : ''"
                            data-vv-as="address line 1"
                            name="formDetails.addressLine1"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">Flat / Room No., Building No., Wing / Floor, Building Name <i
                            aria-hidden="true"
                            class="fas fa-asterisk text-danger"
                            style="font-size: 7px; vertical-align: text-top;"
                          /></span>
                          <span
                            v-show="errors.has('formDetails.addressLine1')"
                            class="help text-danger"
                          >{{ errors.first('formDetails.addressLine1') }}</span>
                        </label>
                      </div>
                      <div class="col-lg-6">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.addressLine2"
                            v-validate="{ required: true, regex: /^[\w\d\s]+$/ }"
                            :class="errors.has('formDetails.addressLine2') ? 'error' : ''"
                            data-vv-as="address line 2"
                            name="formDetails.addressLine2"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">Street Name, Sector, Plot No. <i
                            aria-hidden="true"
                            class="fas fa-asterisk text-danger"
                            style="font-size: 7px; vertical-align: text-top;"
                          /></span>
                          <span
                            v-show="errors.has('formDetails.addressLine2')"
                            class="help text-danger"
                          >{{ errors.first('formDetails.addressLine2') }}</span>
                        </label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-3 col-md-3">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.landmark"
                            v-validate="{ required: true, regex: /^[\w\d\s]+$/ }"
                            :class="errors.has('formDetails.landmark') ? 'error' : ''"
                            data-vv-as="landmark"
                            name="formDetails.landmark"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">Landmark <i
                            aria-hidden="true"
                            class="fas fa-asterisk text-danger"
                            style="font-size: 7px; vertical-align: text-top;"
                          /></span>
                          <span
                            v-show="errors.has('formDetails.landmark')"
                            class="help text-danger"
                          >{{ errors.first('formDetails.landmark') }}</span>
                        </label>
                      </div>
                      <div class="col-lg-3 col-md-3">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.station"
                            v-validate="{ required: true, regex: /^[\w\d\s]+$/ }"
                            :class="errors.has('formDetails.station') ? 'error' : ''"
                            data-vv-as="station"
                            name="formDetails.station"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">Station <i
                            aria-hidden="true"
                            class="fas fa-asterisk text-danger"
                            style="font-size: 7px; vertical-align: text-top;"
                          /></span>
                          <span
                            v-show="errors.has('formDetails.station')"
                            class="help text-danger"
                          >{{ errors.first('formDetails.station') }}</span>
                        </label>
                      </div>
                      <div class="col-lg-3 col-md-3">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.city"
                            v-validate="{ required: true, regex: /^[\w\d\s]+$/ }"
                            :class="errors.has('formDetails.city') ? 'error' : ''"
                            data-vv-as="city"
                            name="formDetails.city"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">City <i
                            aria-hidden="true"
                            class="fas fa-asterisk text-danger"
                            style="font-size: 7px; vertical-align: text-top;"
                          /></span>
                          <span
                            v-show="errors.has('formDetails.city')"
                            class="help text-danger"
                          >{{ errors.first('formDetails.city') }}</span>
                        </label>
                      </div>
                      <div class="col-lg-3 col-md-3">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.zipcode"
                            v-validate="{required: true, digits: 6}"
                            :class="errors.has('formDetails.zipcode') ? 'error' : ''"
                            data-vv-as="pincode"
                            name="formDetails.zipcode"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">Pincode <i
                            aria-hidden="true"
                            class="fas fa-asterisk text-danger"
                            style="font-size: 7px; vertical-align: text-top;"
                          /></span>
                          <span
                            v-show="errors.has('formDetails.zipcode')"
                            class="help text-danger"
                          >{{ errors.first('formDetails.zipcode') }}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row m-b-lg">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-sm-12">
                        <h3>Employment Details</h3>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-3 col-md-6">
                        <div class="non-float-input">
                          <label>Employment Status: <i
                            aria-hidden="true"
                            class="fas fa-asterisk text-danger"
                            style="font-size: 7px; vertical-align: text-top;"
                          /></label>
                          <div class="radio-input-group">
                            <div class="radio-inline">
                              <label>
                                <input
                                  v-model="formDetails.employmentStatus"
                                  v-validate="'required'"
                                  :class="errors.has('formDetails.employmentStatus') ? 'error' : ''"
                                  class="form-control"
                                  data-vv-as="employment status"
                                  name="formDetails.employmentStatus"
                                  type="radio"
                                  value="SALARIED"
                                >Salaried
                              </label>
                            </div>
                            <div class="radio-inline">
                              <label>
                                <input
                                  v-model="formDetails.employmentStatus"
                                  class="form-control"
                                  name="formDetails.employmentStatus"
                                  type="radio"
                                  value="SELF EMPLOYED"
                                >Self Employed
                              </label>
                            </div>
                          </div>
                        </div>
                        <span
                          v-show="errors.has('formDetails.employmentStatus')"
                          class="help text-danger"
                        >{{ errors.first('formDetails.employmentStatus') }}</span>
                      </div>
                      <div class="col-lg-3 col-md-6">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.netMonthlyIncome"
                            v-validate="'required'"
                            :class="errors.has('formDetails.netMonthlyIncome') ? 'error' : ''"
                            data-vv-as="net monthly income"
                            name="formDetails.netMonthlyIncome"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">Net Monthly Salary / Income <i
                            aria-hidden="true"
                            class="fas fa-asterisk text-danger"
                            style="font-size: 7px; vertical-align: text-top;"
                          /></span>
                          <span
                            v-show="errors.has('formDetails.netMonthlyIncome')"
                            class="help text-danger"
                          >{{ errors.first('formDetails.netMonthlyIncome') }}</span>
                        </label>
                      </div>
                      <div class="col-lg-2 col-md-6">
                        <div class="non-float-input">
                          <label>Company Listed ?</label>
                          <div class="radio-input-group">
                            <div class="radio-inline">
                              <label>
                                <input
                                  v-model="formDetails.companyListed"
                                  :class="errors.has('formDetails.companyListed') ? 'error' : ''"
                                  class="form-control"
                                  name="formDetails.companyListed"
                                  type="radio"
                                  value="LISTED"
                                >Yes
                              </label>
                            </div>
                            <div class="radio-inline">
                              <label>
                                <input
                                  v-model="formDetails.companyListed"
                                  class="form-control"
                                  name="formDetails.companyListed"
                                  type="radio"
                                  value="NOT LISTED"
                                >No
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-6">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.company"
                            v-validate="'required'"
                            :class="errors.has('formDetails.company') ? 'error' : ''"
                            data-vv-as="company name"
                            name="formDetails.company"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">Company Name <i
                            aria-hidden="true"
                            class="fas fa-asterisk text-danger"
                            style="font-size: 7px; vertical-align: text-top;"
                          /></span>
                          <span
                            v-show="errors.has('formDetails.company')"
                            class="help text-danger"
                          >{{ errors.first('formDetails.company') }}</span>
                        </label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <h4>Company Address</h4>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.companyAddressLine1"
                            v-validate="{ required: true, min: 3, max: 39, regex: /^[\w\d\s]+$/ }"
                            :class="errors.has('formDetails.companyAddressLine1') ? 'error' : ''"
                            data-vv-as="address line 1"
                            name="formDetails.companyAddressLine1"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">Office / Gala No., Building No., Wing / Floor, Building Name <i
                            aria-hidden="true"
                            class="fas fa-asterisk text-danger"
                            style="font-size: 7px; vertical-align: text-top;"
                          /></span>
                          <span
                            v-show="errors.has('formDetails.companyAddressLine1')"
                            class="help text-danger"
                          >{{ errors.first('formDetails.companyAddressLine1') }}</span>
                        </label>
                      </div>
                      <div class="col-lg-6">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.companyAddressLine2"
                            v-validate="{ required: true, regex: /^[\w\d\s]+$/ }"
                            :class="errors.has('formDetails.companyAddressLine2') ? 'error' : ''"
                            data-vv-as="address line 2"
                            name="formDetails.companyAddressLine2"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">Street Name, Sector, Plot No. <i
                            aria-hidden="true"
                            class="fas fa-asterisk text-danger"
                            style="font-size: 7px; vertical-align: text-top;"
                          /></span>
                          <span
                            v-show="errors.has('formDetails.companyAddressLine2')"
                            class="help text-danger"
                          >{{ errors.first('formDetails.companyAddressLine2') }}</span>
                        </label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-3 col-md-3">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.companyLandmark"
                            v-validate="{ required: true, regex: /^[\w\d\s]+$/ }"
                            :class="errors.has('formDetails.companyLandmark') ? 'error' : ''"
                            data-vv-as="landmark"
                            name="formDetails.companyLandmark"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">Landmark <i
                            aria-hidden="true"
                            class="fas fa-asterisk text-danger"
                            style="font-size: 7px; vertical-align: text-top;"
                          /></span>
                          <span
                            v-show="errors.has('formDetails.companyLandmark')"
                            class="help text-danger"
                          >{{ errors.first('formDetails.companyLandmark') }}</span>
                        </label>
                      </div>
                      <div class="col-lg-3 col-md-3">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.companyStation"
                            v-validate="{ required: true, regex: /^[\w\d\s]+$/ }"
                            :class="errors.has('formDetails.companyStation') ? 'error' : ''"
                            data-vv-as="station"
                            name="formDetails.companyStation"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">Station <i
                            aria-hidden="true"
                            class="fas fa-asterisk text-danger"
                            style="font-size: 7px; vertical-align: text-top;"
                          /></span>
                          <span
                            v-show="errors.has('formDetails.companyStation')"
                            class="help text-danger"
                          >{{ errors.first('formDetails.companyStation') }}</span>
                        </label>
                      </div>
                      <div class="col-lg-3 col-md-3">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.companyCity"
                            v-validate="{ required: true, regex: /^[\w\d\s]+$/ }"
                            :class="errors.has('formDetails.companyCity') ? 'error' : ''"
                            data-vv-as="city"
                            name="formDetails.companyCity"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">City <i
                            aria-hidden="true"
                            class="fas fa-asterisk text-danger"
                            style="font-size: 7px; vertical-align: text-top;"
                          /></span>
                          <span
                            v-show="errors.has('formDetails.companyCity')"
                            class="help text-danger"
                          >{{ errors.first('formDetails.companyCity') }}</span>
                        </label>
                      </div>
                      <div class="col-lg-3 col-md-3">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.companyZipcode"
                            v-validate="{required: true, digits: 6}"
                            :class="errors.has('formDetails.companyZipcode') ? 'error' : ''"
                            data-vv-as="pincode"
                            name="formDetails.companyZipcode"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">Pincode <i
                            aria-hidden="true"
                            class="fas fa-asterisk text-danger"
                            style="font-size: 7px; vertical-align: text-top;"
                          /></span>
                          <span
                            v-show="errors.has('formDetails.companyZipcode')"
                            class="help text-danger"
                          >{{ errors.first('formDetails.companyZipcode') }}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row m-b-lg">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-sm-12">
                        <h3>For Card To Card Offer</h3>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <h4>Existing Bank 1</h4>
                      </div>
                      <div class="col-lg-3 col-md-6">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.extCCBank1"
                            v-validate="{ required: isCardToCardOffer }"
                            :class="errors.has('formDetails.extCCBank1') ? 'error' : ''"
                            data-vv-as="CC bank"
                            name="formDetails.extCCBank1"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">1 Existing CC. Bank
                            <span v-show="isCardToCardOffer">
                              <i
                                v-show="isCardToCardOffer"
                                aria-hidden="true"
                                class="fas fa-asterisk text-danger"
                                style="font-size: 7px; vertical-align: text-top;"
                              />
                            </span>
                          </span>
                          <span
                            v-show="errors.has('formDetails.extCCBank1')"
                            class="help text-danger"
                          >{{ errors.first('formDetails.extCCBank1') }}</span>
                        </label>
                      </div>
                      <div class="col-lg-3 col-md-6">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.extCCUsingFrom1"
                            v-validate="{ required: isCardToCardOffer, numeric: true, min_value: 1, max_value: 999 }"
                            :class="errors.has('formDetails.extCCUsingFrom1') ? 'error' : ''"
                            data-vv-as="CC using from"
                            name="formDetails.extCCUsingFrom1"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">Using From (In Months)
                            <span v-show="isCardToCardOffer">
                              <i
                                v-show="isCardToCardOffer"
                                aria-hidden="true"
                                class="fas fa-asterisk text-danger"
                                style="font-size: 7px; vertical-align: text-top;"
                              />
                            </span>
                          </span>
                          <span
                            v-show="errors.has('formDetails.extCCUsingFrom1')"
                            class="help text-danger"
                          >{{ errors.first('formDetails.extCCUsingFrom1') }}</span>
                        </label>
                      </div>
                      <div class="col-lg-3 col-md-6">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.extCCTotalLimit1"
                            v-validate="{ required: isCardToCardOffer }"
                            :class="errors.has('formDetails.extCCTotalLimit1') ? 'error' : ''"
                            data-vv-as="CC total limit"
                            name="formDetails.extCCTotalLimit1"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">Total Limit
                            <span v-show="isCardToCardOffer">
                              <i
                                v-show="isCardToCardOffer"
                                aria-hidden="true"
                                class="fas fa-asterisk text-danger"
                                style="font-size: 7px; vertical-align: text-top;"
                              />
                            </span>
                          </span>
                          <span
                            v-show="errors.has('formDetails.extCCTotalLimit1')"
                            class="help text-danger"
                          >{{ errors.first('formDetails.extCCTotalLimit1') }}</span>
                        </label>
                      </div>
                      <div class="col-lg-3 col-md-6">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.extCCAvailableLimit1"
                            v-validate="{ required: isCardToCardOffer }"
                            :class="errors.has('formDetails.extCCAvailableLimit1') ? 'error' : ''"
                            data-vv-as="CC available limit"
                            name="formDetails.extCCAvailableLimit1"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">Available Limit
                            <span v-show="isCardToCardOffer">
                              <i
                                v-show="isCardToCardOffer"
                                aria-hidden="true"
                                class="fas fa-asterisk text-danger"
                                style="font-size: 7px; vertical-align: text-top;"
                              />
                            </span>
                          </span>
                          <span
                            v-show="errors.has('formDetails.extCCAvailableLimit1')"
                            class="help text-danger"
                          >{{ errors.first('formDetails.extCCAvailableLimit1') }}</span>
                        </label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <h4>Existing Bank 2</h4>
                      </div>
                      <div class="col-lg-3 col-md-6">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.extCCBank2"
                            name="formDetails.extCCBank2"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">2 Existing CC. Bank</span>
                        </label>
                      </div>
                      <div class="col-lg-3 col-md-6">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.extCCUsingFrom2"
                            v-validate="{ numeric: true, min_value: 1, max_value: 999 }"
                            :class="errors.has('formDetails.extCCUsingFrom2') ? 'error' : ''"
                            data-vv-as="CC using from"
                            name="formDetails.extCCUsingFrom2"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">Using From (In Months)</span>
                          <span
                            v-show="errors.has('formDetails.extCCUsingFrom2')"
                            class="help text-danger"
                          >{{ errors.first('formDetails.extCCUsingFrom2') }}</span>
                        </label>
                      </div>
                      <div class="col-lg-3 col-md-6">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.extCCTotalLimit2"
                            name="formDetails.extCCTotalLimit2"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">Total Limit</span>
                        </label>
                      </div>
                      <div class="col-lg-3 col-md-6">
                        <label class="has-float-label">
                          <input
                            v-model="formDetails.extCCAvailableLimit2"
                            name="formDetails.extCCAvailableLimit2"
                            placeholder=" "
                            type="text"
                          >
                          <span class="float-label">Available Limit</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="row"
                  style="padding-top: 15px"
                >
                  <div class="col-xs-offset-2 col-xs-8">
                    <div
                      id="consentProof"
                      class="dropzone"
                    >
                      <div
                        id="dropzone-message"
                        style="display: none"
                      >
                        <span class="dropzone-title">Drop files here or click to select</span>
                        <span class="dropzone-info">You can upload multiple files at once</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <input
                id="newFormButton"
                class="btn btn-default form-submit"
                type="button"
                value="Submit Form"
                @click.stop.prevent="submitForm"
              >
            </div>
          </div>
        </div>
      </div>
    </div><!-- Row -->
  </div><!-- Main Wrapper -->
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {API, graphqlOperation, Storage} from "aws-amplify";
import * as customQueries from "../../../graphql/customQueries";
import * as mutations from "../../../graphql/mutations";
import {Validator} from 'vee-validate';
import {formFields, leadFeedbackCategories, oclPincodes} from "@/constants";
import Dropzone from "dropzone";
import {v4 as uuidv4} from 'uuid';

export default {
  name: "RBLForm",
  data() {
    return {
      formDetails: {
        offerType: 'CARD TO CARD',
        title: '',
        firstName: '',
        fatherName: '',
        lastName: '',
        pancard: '',
        dateOfBirth: '',
        age: null,
        gender: '',
        email: '',
        mobileNumber: '',
        mobileNumberAlternate: '',
        addressLine1: '',
        addressLine2: '',
        landmark: '',
        city: '',
        station: '',
        zipcode: '',
        employmentStatus: '',
        netMonthlyIncome: '',
        companyListed: '',
        company: '',
        companyAddressLine1: '',
        companyAddressLine2: '',
        companyCity: '',
        companyLandmark: '',
        companyStation: '',
        companyZipcode: '',
        extCCBank1: '',
        extCCUsingFrom1: '',
        extCCTotalLimit1: '',
        extCCAvailableLimit1: '',
        extCCBank2: '',
        extCCUsingFrom2: '',
        extCCTotalLimit2: '',
        extCCAvailableLimit2: ''
      },
      documentUploader: null,
    };
  },
  computed: {
    ...mapGetters([
      "getLoggedInUser",
      "getStatuses",
      "getUsers",
      "getForms",
      "getLeads",
      "getLeadFeedbacks",
      "getSettings"
    ]),
    isCardToCardOffer: function () {
      return this.formDetails.offerType === 'CARD TO CARD';
    },
    getOclPincodes: function () {
      return oclPincodes;
    },
    calcAge: function () {
      let today = new Date();
      let dateChunks = this.formDetails.dateOfBirth.split('-');
      let birthDate = new Date(dateChunks[2] + '-' + dateChunks[1] + '-' + dateChunks[0]);
      let age = today.getFullYear() - birthDate.getFullYear();
      let m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      if (birthDate instanceof Date && !isNaN(age)) {
        if (age < 0 || age > 100) {
          return 0;
        } else {
          return age;
        }
      } else {
        return null;
      }
    }
  },
  beforeMount() {
    const dictionary = {
      en: {
        messages: {
          included: () => 'Pincode is Out of City Limit'
        }
      }
    };

    // Override and merge the dictionaries
    Validator.localize(dictionary);

    Validator.extend('age-check', {
      getMessage: () => 'The age must be 23-60 years',
      validate: (value) => {
        let today = new Date();
        let dateChunks = value.split('-');
        let birthDate = new Date(dateChunks[2] + '-' + dateChunks[1] + '-' + dateChunks[0]);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        if (birthDate instanceof Date && !isNaN(age)) {
          return !(age < 23 || age > 60);
        } else {
          return false;
        }
      }
    });
  },
  created() {
    Dropzone.autoDiscover = false;
  },
  mounted() {
    $('#sidebar').removeClass('visible');
    $('.page-inner').removeClass('sidebar-visible');

    $('.date-picker').change((e) => {
      this.formDetails.dateOfBirth = $(e.target).val();
    });

    $(':radio').click((e) => {
      e.preventDefault();
      e.stopPropagation();

      _.set(this, e.currentTarget.name, e.currentTarget.value);
    });

    _.forOwn(formFields, (field, fieldKey) => {
      $('input[name="formDetails.' + fieldKey + '"]').keypress((e) => {
        e.preventDefault();
        if (field !== 'Radio') {
          if (field === 'Currency') {
            if (!(e.keyCode < 48 || e.keyCode > 57)) {
              let start = e.target.selectionStart;
              let end = e.target.selectionEnd;
              let value = e.target.value.substring(0, start) + String.fromCharCode(e.keyCode) + e.target.value.substring(end);
              _.set(this, e.target.name, this.convertToCurrency(value));
            }
          } else if (field === 'Number' || field === 'Phone') {
            if (!(e.keyCode < 48 || e.keyCode > 57)) {
              let start = e.target.selectionStart;
              let end = e.target.selectionEnd;
              _.set(this, e.target.name, e.target.value.substring(0, start) + String.fromCharCode(e.keyCode) + e.target.value.substring(end));
              this.$nextTick(() => {
                e.target.setSelectionRange(start + 1, start + 1);
              });
            }
          } else {
            let newCharUpper = field === 'Email' ? _.toLower(String.fromCharCode(e.keyCode)) : _.toUpper(String.fromCharCode(e.keyCode));
            let start = e.target.selectionStart;
            let end = e.target.selectionEnd;
            _.set(this, e.target.name, e.target.value.substring(0, start) + newCharUpper + e.target.value.substring(end));
            this.$nextTick(() => {
              e.target.setSelectionRange(start + 1, start + 1);
            });
          }
        }
      });
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        //this is because keypress is not recognized on mobile
        $('input[name="formDetails.' + fieldKey + '"]').change((e) => {
          e.preventDefault();
          if (field !== 'Radio') {
            if (field === 'Currency') {
              // if (!(e.keyCode < 48 || e.keyCode > 57)) {
              // 	let start = e.target.selectionStart;
              // 	let end = e.target.selectionEnd;
              // 	let value = e.target.value.substring(0, start) + String.fromCharCode(e.keyCode) + e.target.value.substring(end);
              // 	_.set(this, e.target.name, this.convertToCurrency(value));
              // }
            } else if (field === 'Number' || field === 'Phone') {
              // if (!(e.keyCode < 48 || e.keyCode > 57)) {
              // 	let start = e.target.selectionStart;
              // 	let end = e.target.selectionEnd;
              // 	_.set(this, e.target.name, e.target.value.substring(0, start) + String.fromCharCode(e.keyCode) + e.target.value.substring(end));
              // this.$nextTick(() => {
              // 	e.target.setSelectionRange(start + 1, start + 1);
              // });
              // }
            } else {
              _.set(this, e.target.name, field === 'Email' ? _.toLower(e.target.value) : _.toUpper(e.target.value));
            }
          }
        });
      }
    });
    if (this.$route.params.mobileNumber && this.$route.params.mobileNumber !== '') {
      this.formDetails.mobileNumberAlternate = this.$route.params.mobileNumber;
    }
    let options = {
      url: '/',
      method: 'put',
      acceptedFiles: "image/jpeg,image/png,application/pdf",
      addRemoveLinks: true,
      parallelUploads: 1,
      uploadMultiple: false,
      header: '',
      dictDefaultMessage: document.querySelector('#dropzone-message').innerHTML,
      autoProcessQueue: false
    };

    // Instantiate DropZone
    this.documentUploader = new Dropzone("div#consentProof", options);
  },
  methods: {
    ...mapMutations([
      "UPDATE_FORMS",
      "UPDATE_LEADS"
    ]),
    setMessage: function (type, title, message, timeout = 6000) {
      let content = this.$Amplify.I18n.get(message.message || message);

      if (type === 'alert' || type === 'confirm') {
        return this.$Msg.add(content, {
          type: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      } else {
        this.$Msg.add(content, {
          theme: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      }
    },
    submitForm() {
      $('#newFormButton').attr('disabled', true);
      this.$validator.validateAll()
          .then((result) => {
            if (result) {
              this.validationSuccessful();
            } else {
              $('#newFormButton').attr('disabled', false);
              let firstErrorInput = $('input.error:first')[0];
              if (firstErrorInput) {
                firstErrorInput.scrollIntoView({behavior: 'instant'});
                firstErrorInput.focus();
              }
              if (this.errors.items && this.errors.items.length > 1) {
                this.setMessage('v-notify-error', "Oops!", "Please correct the " + this.errors.items.length + " errors on the form.");
              } else {
                let errorInputName = firstErrorInput.dataset.vvAs || firstErrorInput.name || 'form';
                if (errorInputName && errorInputName !== '') {
                  errorInputName = _.upperCase(_.replace(errorInputName, "formDetails.", ""));
                  this.setMessage('v-notify-error', "So Close!", "There is an issue with the " + errorInputName + " field.");
                } else {
                  this.setMessage('v-notify-error', "Sorry!", "Errors in the form!");
                }
              }
            }
          })
          .catch(() => {
            $('#newFormButton').attr('disabled', false);
            let firstErrorInput = $('input.error:first')[0];
            if (firstErrorInput) {
              firstErrorInput.scrollIntoView({behavior: 'instant'});
              firstErrorInput.focus();
            }
            if (this.errors.items && this.errors.items.length > 1) {
              this.setMessage('v-notify-error', "Oops!", "Please correct the " + this.errors.items.length + " errors on the form.");
            } else {
              let errorInputName = firstErrorInput.dataset.vvAs || firstErrorInput.name || 'form';
              if (errorInputName && errorInputName !== '') {
                errorInputName = _.upperCase(_.replace(errorInputName, "formDetails.", ""));
                this.setMessage('v-notify-error', "So Close!", "There is an issue with the " + errorInputName + " field.");
              } else {
                this.setMessage('v-notify-error', "Sorry!", "Errors in the form!");
              }
            }
          });
    },
    async validationSuccessful() {
      try {
        const currentTimeEpoch = moment().format('X');
        const {
          data: {
            formsByPancardProduct: {items}
          }
        } = await API.graphql(graphqlOperation(customQueries.countFormsByPancardProductMinimal, {
          pancard: _.toUpper(this.formDetails.pancard),
          product: {
            eq: this.formDetails.product
          },
          limit: 999
        }));
        if (items.length > 0) {
          $('#newFormButton').attr('disabled', false);
          let existingForm = items[0];
          let existingFormStatusId = existingForm.status;
          let existingFormStatus = _.result(_.find(this.getStatuses, {id: existingFormStatusId}), 'name');
          if (existingFormStatusId === '1fdca13d-adf6-411a-a002-a33e228d22df' || existingFormStatusId === '7795614b-720c-4581-9309-4f569895ac8d') {
            this.setMessage('v-notify-error', "Duplicate Pancard In System!", 'Previously: ' + existingFormStatus + ' @ ' + moment(existingForm.createdAtEpoch, 'X').format('hh:mm a, DD-MM-YYYY'));
          } else if (existingFormStatusId === '60bf9d14-ea35-44a7-88eb-30ff2eaa1712') {
            this.setMessage('v-notify-error', "Duplicate Pancard In System!", 'Previously: ' + existingFormStatus + ' on ' + moment(existingForm.previouslyAppliedDate, 'YYYY-MM-DD').format('DD-MM-YYYY'));
          } else {
            this.setMessage('v-notify-error', "Duplicate Pancard In System!", 'Previously: ' + existingFormStatus + ' @ ' + moment(existingForm.bankVerificationEpoch, 'X').format('hh:mm a, DD-MM-YYYY'));
          }
          return;
        }
        try {
          this.formDetails.submittedDocuments = [];
          let files = this.documentUploader.getQueuedFiles();
          if (files && files.length > 0) {
            let documents = [];
            this.formDetails.id = uuidv4();
            _.forEach(files, file => {
              documents.push(Storage.put(this.formDetails.id + '/' + file.name, file, {
                level: 'public',
                contentType: file.type
              }));
            });
            await Promise.all(documents)
                .then(results => {
                  _.forEach(results, (result) => {
                    let documentKey = _.split(result.key, '/')[1];
                    this.formDetails.submittedDocuments.push(documentKey);
                  });
                }).catch(() => {
                  $('#newFormButton').attr('disabled', false);
                  this.setMessage('v-notify-error', "Sorry!", "There was an error uploading your documents. Please try again later.");
                });
          }
        } catch (e) {
          $('#newFormButton').attr('disabled', false);
          this.setMessage('v-notify-error', "Sorry!", "There was an error uploading your documents. Please try again later.");
        }
        this.formDetails.age = this.calcAge;
        this.formDetails.createdAtEpoch = currentTimeEpoch;
        this.formDetails.nameDesired = this.formDetails.firstName + ' ' + this.formDetails.lastName;

        let dateChunks = this.formDetails.dateOfBirth.split('-');
        this.formDetails.dateOfBirth = dateChunks[2] + '-' + dateChunks[1] + '-' + dateChunks[0];  //NOTE: RBL date: dd-mm-yyyy, amazon date: ISO standard yyyy-mm-dd

        let attemptNumber = 0;
        while ((!this.getLoggedInUser.branchId || this.getLoggedInUser.branchId[0] === '') && attemptNumber < 5) {
          attemptNumber++;
        }
        if (this.getLoggedInUser.branchId && this.getLoggedInUser.branchId[0] !== '') {
          this.formDetails.branchId = this.getLoggedInUser.branchId[0];
        }
        _(formFields)
            .pickBy(field => (field === 'Currency' || field === 'Number'))
            .forOwn((field, fieldKey) => {
              if (this.formDetails[fieldKey] && this.formDetails[fieldKey] !== '') {
                this.formDetails[fieldKey] = _.parseInt(_.replace(this.formDetails[fieldKey], /,/g, ""));
                if (_.isNaN(this.formDetails[fieldKey])) {
                  this.formDetails[fieldKey] = null;
                }
              } else {
                this.formDetails[fieldKey] = null;
              }
            });

        _(formFields)
            .pickBy(field => (field === 'String' || field === 'Radio' || field === 'Email'))
            .forOwn((field, fieldKey) => {
              if (this.formDetails[fieldKey] && this.formDetails[fieldKey] !== '') {
                this.formDetails[fieldKey] = _.trim(this.formDetails[fieldKey]);
                if (field === 'Email') {
                  this.formDetails[fieldKey] = _.toLower(this.formDetails[fieldKey]);
                } else if (field === 'String' && fieldKey !== 'owner') {
                  this.formDetails[fieldKey] = _.toUpper(this.formDetails[fieldKey]);
                }
              } else {
                this.formDetails[fieldKey] = null;
              }
            });

        this.formDetails.status = '1fdca13d-adf6-411a-a002-a33e228d22df'; //NOTE: Hardcoded ids = Bad idea
        this.formDetails.submittedBy = "EMPLOYEE";
        this.formDetails.product = 'c03143c4-bcfb-4526-a86f-ca29fc402b78';
        this.formDetails.updatedBy = this.getLoggedInUser.username;
        this.formDetails.updatedAtEpoch = currentTimeEpoch;
        let supervisor = _.find(this.getUsers, (user) => {
          return user.id === this.getLoggedInUser.supervisor;
        });
        if (supervisor) {
          this.formDetails.supervisor = supervisor.username;

          let manager = _.find(this.getUsers, (user) => {
            return user.id === supervisor.supervisor;
          });

          if (manager) {
            this.formDetails.branchManager = manager.username;
          }
        }

        if (this.$route.params.leadId && this.$route.params.leadId !== '') {
          this.formDetails.formLeadId = this.$route.params.leadId;
        }
        const {
          data: {createForm}
        } = await API.graphql(graphqlOperation(mutations.createForm, {
          input: _.omitBy(this.formDetails, (field) => {
            return (!field) || field === '';
          })
        }));

        if (this.$route.params.leadId && this.$route.params.leadId !== '') {
          await this.updateFormLead();
        }

        let oldForms = _.cloneDeep(this.getForms);
        oldForms.push(createForm);
        this.UPDATE_FORMS(_.uniqBy(oldForms, 'id'));
        this.setMessage('v-notify-success', "Woohoo!", "The form was submitted successfully!");
        await this.$router.push({name: 'home'});
      } catch (e) {
        $('#newFormButton').attr('disabled', false);
        this.setMessage('v-notify-error', "Error!", e);
      }
    },
    async updateFormLead() {
      const currentTimeEpoch = moment().format('X');
      let newLead = {
        id: this.$route.params.leadId,
        assignedTo: null,
        isAssigned: 'NA',
        isInterested: 'NO',
        isCallLater: 'NO',
        isUnusable: 'NO',
        isReusable: 'NO',
        isNoContact: 'NO',
        noContactAttempt: null,
        feedback: null,
        isClosed: 'YES',
        remarks: null,
        updatedAtEpoch: currentTimeEpoch,
        expectedVersion: this.$route.params.expectedVersion
      };

      try {
        const {
          data: {updateLead}
        } = await API.graphql(graphqlOperation(mutations.updateLead, {
          input: newLead
        }));
        let updatedLead = updateLead;

        let oldLeads = _.cloneDeep(this.getLeads);
        let leadIndex = _.findIndex(oldLeads, (oldLead) => {
          return oldLead.id === updatedLead.id;
        });
        if (leadIndex !== -1) {
          _.remove(oldLeads, (lead) => {
            return lead.id === updatedLead.id;
          });
          this.UPDATE_LEADS(oldLeads);
        }
        let closedFeedback = _.result(_.find(this.getLeadFeedbacks, (feedback) => {
          return feedback.category === leadFeedbackCategories.CLOSED && _.includes(feedback.products, 'c03143c4-bcfb-4526-a86f-ca29fc402b78');
        }), 'id', 'Form Created');
        await this.createLog(updatedLead.id, closedFeedback);
      } catch (err) {
        console.error(err);
      }
    },
    async createLog(leadId, action) {
      const currentTimeEpoch = moment().format('X');
      let retentionDays = moment(currentTimeEpoch, 'X').add(30, 'days');
      const retentionSetting = _.find(this.getSettings, ['name', 'leadHistoryRetention']);
      if (retentionSetting && retentionSetting.value) {
        try {
          retentionDays = moment(currentTimeEpoch, 'X').add(_.parseInt(retentionSetting.value), 'days');
        } catch (e) {
          retentionDays = moment(currentTimeEpoch, 'X').add(30, 'days');
        }
      }
      try {
        let supervisor = _.find(this.getUsers, (user) => {
          return user.id === this.getLoggedInUser.supervisor;
        });
        let manager = {};
        if (supervisor) {
          manager = _.find(this.getUsers, (user) => {
            return user.id === supervisor.supervisor;
          });
        }

        await API.graphql(graphqlOperation(mutations.createLog, {
          input: {
            logLeadId: leadId,
            action: "LEAD",
            supervisor: _.result(supervisor, 'username'),
            branchManager: _.result(manager, 'username'),
            actionType: action,
            actionEpoch: currentTimeEpoch,
            expirationEpoch: _.parseInt(retentionDays.format('X'))
          }
        }));
      } catch (err) {
        console.error(err);
      }
    },
    convertToCurrency: function (value) {
      value = value.toString().replace(/,/g, "");

      let lastThree = value.substring(value.length - 3);
      let otherNumbers = value.substring(0, value.length - 3);

      if (otherNumbers !== "") {
        lastThree = "," + lastThree;
      }

      return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    }
  }
};
</script>

<style scoped/>
